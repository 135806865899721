/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    code: "code",
    pre: "pre",
    h1: "h1",
    a: "a",
    div: "div",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "There are 2 required props: ", React.createElement(_components.code, null, "short"), " and ", React.createElement(_components.code, null, "full"), " values.\nThe component will make equality check and tooltip will be shown in the case of ", React.createElement(_components.code, null, "short"), " and ", React.createElement(_components.code, null, "full"), " values aren't equal."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<div>\n  <MoreDetails full=\"10.33\" short=\"10.33\" />\n  <br />\n  <MoreDetails full=\"10.364846\" short=\"10.36\" />\n</div>\n")), "\n", React.createElement(_components.h1, {
    id: "sizes",
    style: {
      position: "relative"
    }
  }, "Sizes", React.createElement(_components.a, {
    href: "#sizes",
    "aria-label": "sizes permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "size"), " property is the same as in ", React.createElement(_components.code, null, "BodyText"), " component.\nIt will be automatically passed to ", React.createElement(_components.code, null, "BodyText"), " component inside the ", React.createElement(_components.code, null, "MoreDetails"), " component and icon will change it's size accordingly."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<div>\n  <MoreDetails full=\"10.326546\" short=\"10.33\" size=\"xsmall\" />\n  <br />\n  <MoreDetails full=\"10.326546\" short=\"10.33\" size=\"small\" />\n  <br />\n  <MoreDetails full=\"10.326546\" short=\"10.33\" size=\"medium\" />\n  <br />\n  <MoreDetails full=\"10.326546\" short=\"10.33\" size=\"large\" />\n</div>\n")), "\n", React.createElement(_components.h1, {
    id: "tooltip-direction",
    style: {
      position: "relative"
    }
  }, "Tooltip Direction", React.createElement(_components.a, {
    href: "#tooltip-direction",
    "aria-label": "tooltip direction permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Tooltip direction can be passed by ", React.createElement(_components.code, null, "direction"), " property. It accepts all the values as Tooltip components direction property."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<div>\n  <MoreDetails full=\"10.326546\" short=\"10.33\" direction=\"r\" />\n  <br />\n  <MoreDetails full=\"10.364846\" short=\"10.3648\" direction=\"bottom\" />\n</div>\n")), "\n", React.createElement(_components.h1, {
    id: "classnames",
    style: {
      position: "relative"
    }
  }, "ClassNames", React.createElement(_components.a, {
    href: "#classnames",
    "aria-label": "classnames permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Each element inside the component has its own className."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "className"), " prop is for wrapper ", React.createElement(_components.code, null, "Stack"), " element,"), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "labelClassName"), " prop is for ", React.createElement(_components.code, null, "BodyText"), " element,"), "\n", React.createElement(_components.li, null, React.createElement(_components.code, null, "iconClassName"), " props is for wrapper of ", React.createElement(_components.code, null, "Icon"), "."), "\n"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<div>\n  <MoreDetails full=\"10.326546\" short=\"10.33\" className=\"m-b-1\" />\n  <br />\n  <MoreDetails full=\"10.326546\" short=\"10.33\" labelClassName=\"c-blue-300\" />\n  <br />\n  <MoreDetails full=\"10.326546\" short=\"10.33\" iconClassName=\"m-l-4\" />\n</div>\n")), "\n", React.createElement(_components.h1, {
    id: "importing",
    style: {
      position: "relative"
    }
  }, "Importing", React.createElement(_components.a, {
    href: "#importing",
    "aria-label": "importing permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "code: true\n---\nimport { MoreDetails } from '@servicetitan/design-system';\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
